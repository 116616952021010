var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"58103675932e34bdc22bb198991dcaea5a6063e0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import getConfig from 'next/config'
import * as Sentry from '@sentry/nextjs'

const { publicRuntimeConfig: config } = getConfig()

const dsn = config.sentryDsn
// in preview apps config.nodeEnv = 'production' but config.environment = 'preview'
const nodeEnv = config.nodeEnv
const environment = config.environment

if (dsn && nodeEnv === 'production') {
  Sentry.init({
    dsn,
    tracesSampleRate: 1.0,
    hideSourceMaps: false,
    environment: environment,
    release: 'kikin-app@' + config.vercelGitCommitSha,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
  })
}
